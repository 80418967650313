import React, { useState, useEffect } from "react";
import "../newStyles.scss";
import { withRouter } from "react-router-dom";
import Options from "./Options";
import ChangeEmail from "./ChangeEmail";
import ChangePhone from "./ChangePhone";
import ChangePassword from "./ChangePassword";
import ChangePayment from "./ChangePayment";
import PaymentBank from "./payment/PaymentBank";
import PaymentDebit from "./payment/PaymentDebit";
import cookie from "react-cookies";
import LinkButton from "../../common/LinkButton";
import { getCustomerByMail, getDirectDebitInformation } from "../../../services/api";

const idProduct = 1;

const NewProfile = (props) => {
  const [user, setUser] = useState(null);
  const [token, setToken] = useState(null);
  const [section, setSection] = useState("home");
  const [maskedData, setMaskedData] = useState("****************");

  useEffect(() => {
    let getUser = JSON.parse(sessionStorage.getItem("loggedUser"));
    if (!getUser) return props.history.push("/login");
    setUser(getUser);
    if (getUser.eMail === "demo@demo.com") return;
    let getToken = cookie.load("token");
    if (!getToken) return props.history.push("/login");
    setToken(getToken);
  }, [section]);

  useEffect(() => {
    if (user) {
      let getToken = cookie.load("token");
      if (!getToken) return props.history.push("/login");
      getDirectDebitInformation({ idProduct, idCustomer: user?.customerId }, getToken)
        .then((res) => {
          if (res.data.bank == "N/A") {
            setMaskedData("**************" + res.data.bankAccountNumber?.substr(res.data.bankAccountNumber.length - 4) + " (CLABE)");
          } else {
            setMaskedData("************" + res.data.bankAccountNumber?.substr(res.data.bankAccountNumber.length - 4) + " (" + res.data.bank + ")");
          }
        })
        .catch((err) => console.log(err));
    }
  }, [user]);

  return (
    <div className="bg-white relative w-full flex justify-center flex-1">
      <div className="container flex flex-col gap-8 px-4 py-12 self-center">
        <div className="w-full flex flex-col gap-2">
          <h1 className="text-3xl lg:text-6xl font-bold text-green-dark">Mis datos</h1>
        </div>
        <div>
          {section === "home" ? (
            <Options setSection={setSection} user={user} maskedBank={maskedData} />
          ) : section === "email" ? (
            <ChangeEmail setSection={setSection} history={props.history} />
          ) : section === "phone" ? (
            <ChangePhone setSection={setSection} history={props.history} />
          ) : section === "password" ? (
            <ChangePassword setSection={setSection} />
          ) : section === "payment" ? (
            <ChangePayment setSection={setSection} />
          ) : section === "payment-bank" ? (
            <PaymentBank history={props.history} setSection={setSection} />
          ) : section === "payment-debit" ? (
            <PaymentDebit history={props.history} setSection={setSection} />
          ) : (
            <Options setSection={setSection} user={user} />
          )}
        </div>
        <div className="mt-8">
          <LinkButton to="/dashboard/payment">Regresar a dashboard principal</LinkButton>
        </div>
      </div>
    </div>
  );
};

export default withRouter(NewProfile);
