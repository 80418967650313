import React, { useState, useEffect } from "react";
import Slider from "react-rangeslider";
import { withRouter } from "react-router-dom";
import "../newStyles.scss";
import { getToken, requestAdditionalAmount, getSimulation } from "../../../services/api";
import { momentEs } from "../../../services/moment";
import { BallClipRotate } from "react-pure-loaders";

const idProduct = 1;

const More = (props) => {
  let stepWidth = 22;
  let periodicidad = 3;

  const [loadingDone, setLoadingDone] = useState(false);
  const [loadingSimulation, setLoadingSimulation] = useState(false);
  const [loadingCalc, setLoadingCalc] = useState(true);
  const [user, setUser] = useState({});
  const [data, setData] = useState({});
  const [serverError, setServerError] = useState("");
  const [allowed, setAllowed] = useState(false);
  const [balance, setBalance] = useState({});
  //SIMULATION VALUES
  const [amount, setAmount] = useState(0);
  const [interest, setInterest] = useState(0);
  const [plazo, setPlazo] = useState(0);
  const [commision, setCommision] = useState(0);
  // const [table, setTable] = useState([])
  const [firstPayment, setFirstPayment] = useState(0);

  const getLoan = async () => {
    setLoadingDone(true);
    let response = await getToken();
    if (!response) return setServerError("Error en el servidor, Token");
    let validToken = response.data.token;
    const sentData = {
      idProduct,
      idCustomer: user.customerId,
      idAction: 2,
      customerIp: sessionStorage.getItem("ip") ? sessionStorage.getItem("ip") : "0.0.0.0",
      newLoanAmount: amount,
      newLoanTerm: data.term,
      newLoanFrequency: data.frequency,
    };
    requestAdditionalAmount(sentData, validToken)
      .then((res) => {
        setLoadingDone(false);
        if (res.status === 200) return props.history.push("/additional/application/pre-approved");
      })
      .catch((err) => {
        if (err.response.status === 400) {
          setServerError("Error en el servidor, Monto Adicional");
          setAllowed(false);
          setLoadingDone(false);
        }
        if (err.response.status === 404) {
          setServerError("Error 404");
          setAllowed(false);
          setLoadingDone(false);
        }
      });
  };

  const simulate = async () => {
    if (!allowed) return;
    if (user.eMail === "demo@demo.com") {
      setInterest(500);
      return setFirstPayment(2000);
    }
    setLoadingSimulation(true);
    let response = await getToken();
    if (!response) return setServerError("Error en el servidor, Token");
    let validToken = response.data.token;
    if (data.actualLoan) {
      getSimulation(idProduct, amount, data.frequency, data.term, user.customerId, validToken).then((res) => {
        const { data } = res;
        setInterest(data.interest);
        setFirstPayment(data.firstPaymentAmount);
        setCommision(data.commision);
        // setTable(data.amortizationTable)
        setLoadingSimulation(false);
      });
      setLoadingSimulation(false);
    }
  };

  const getData = async (user) => {
    let userBalance = JSON.parse(sessionStorage.getItem("balance"));
    if (userBalance) setBalance(userBalance);
    if (user.eMail === "demo@demo.com") {
      setUser(user);
      setAmount(2000);
      setPlazo(7);
      setData({
        minAmount: 1000,
        maxAmount: 2000,
        actualLoan: 2000,
        term: 7,
        frequency: 3,
        paymentDate: new Date(),
        minPlazo: 7,
        maxPlazo: 30,
        step: 1,
      });
      setLoadingCalc(false);
      return setAllowed(true);
    }
    let response = await getToken();
    if (!response) return setServerError("Error en el servidor, Token");
    let validToken = response.data.token;
    const data = {
      idProduct,
      idCustomer: user.customerId,
      idAction: 1,
      customerIp: sessionStorage.getItem("ip") ? sessionStorage.getItem("ip") : "0.0.0.0",
      newLoanAmount: 0,
      newLoanTerm: 0,
      newLoanFrequency: 0,
    };
    requestAdditionalAmount(data, validToken)
      .then((res) => {
        const { data } = res;
        if (data) {
          setData(data);
          setAmount(data.simulation.amount);
          setLoadingCalc(false);
          setAllowed(true);
          setPlazo(data.term);
          return;
        }
        setServerError("Error en el servidor");
      })
      .catch((err) => {
        if (err.response) {
          if (err.response.status === 400) setServerError(`Error: ${err.response.status}. Error en el servidor`);
          if (err.response.status === 403) setServerError(`Error: ${err.response.status}. No puedes solicitar más dinero`);
          if (err.response.status === 404) setServerError(`Error: ${err.response.status}. No encontrado`);
          setLoadingCalc(false);
        }
      });
  };

  useEffect(() => {
    let demoUser = JSON.parse(sessionStorage.getItem("demoUser"));
    if (demoUser) {
      getData(demoUser);
      return setUser(demoUser);
    }
    let user = JSON.parse(sessionStorage.getItem("loggedUser"));
    setUser(user);
    if (user !== null) getData(user);
  }, []);

  useEffect(() => {
    simulate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allowed]);

  return (
    <>
      {serverError ? (
        <div className="more-container">
          <p>{serverError}</p>
        </div>
      ) : loadingCalc ? (
        <div className="more-container">
          <div className="loading-calc">
            <p>Cargando...</p>
            <BallClipRotate color="white" loading />
          </div>
        </div>
      ) : (
        <div className="more-container">
          <div className="more-container-main">
            <div className="left-monto-option">
              <div className="calc-amounts">
                <p className="dark-green-title">Solicita más dinero</p>
                <p>
                  Puedes solicitar un monto extra de hasta <strong>{data.maxAmount.toLocaleString("en-US", { style: "currency", currency: "USD" })} MXN</strong>
                </p>
              </div>
              <div className="input-box-border">
                <div className="title-winput">
                  <p>Monto total</p>
                  <div className="slider-input-wrapper">
                    <input className="slider-input" type="text" value={amount.toLocaleString("en-US", { style: "currency", currency: "USD" })} readOnly />
                    <span className="slider-input-unit">$</span>
                  </div>
                </div>
                <div className="slider">
                  <div style={{ top: "0.4rem" }} className="slider-steps">
                    <span className="step"></span>
                    <span className="step" style={{ marginLeft: `${stepWidth}px` }}></span>
                    <span className="step" style={{ marginLeft: `${stepWidth}px` }}></span>
                    <span className="step" style={{ marginLeft: `${stepWidth}px` }}></span>
                    <span className="step" style={{ marginLeft: `${stepWidth}px` }}></span>
                    <span className="step" style={{ marginLeft: `${stepWidth}px` }}></span>
                    <span className="step" style={{ marginLeft: `${stepWidth}px` }}></span>
                    <span className="step" style={{ marginLeft: `${stepWidth}px` }}></span>
                    <span className="step" style={{ marginLeft: `${stepWidth}px` }}></span>
                    <span className="step" style={{ marginLeft: `${stepWidth}px` }}></span>
                    <span className="step" style={{ marginLeft: `${stepWidth}px` }}></span>
                    <span className="step" style={{ marginLeft: `${stepWidth}px` }}></span>
                    <span className="step" style={{ marginLeft: `${stepWidth}px` }}></span>
                    <span className="step" style={{ marginLeft: `${stepWidth}px` }}></span>
                    <span className="step" style={{ marginLeft: `${stepWidth}px` }}></span>
                    <span className="step" style={{ marginLeft: `${stepWidth}px` }}></span>
                    <span className="step" style={{ marginLeft: `${stepWidth}px` }}></span>
                    <span className="step" style={{ marginLeft: `${stepWidth}px` }}></span>
                    <span className="step" style={{ marginLeft: `${stepWidth}px` }}></span>
                    <span className="step" style={{ marginLeft: `${stepWidth}px` }}></span>
                    <span className="step" style={{ marginLeft: `${stepWidth}px` }}></span>
                    <span className="step" style={{ marginLeft: `${stepWidth}px` }}></span>
                    <span className="step" style={{ marginLeft: `${stepWidth}px` }}></span>
                    <span className="step" style={{ marginLeft: `${stepWidth}px` }}></span>
                    <span className="step" style={{ marginLeft: `${stepWidth}px` }}></span>
                  </div>
                  <Slider
                    min={data.minAmount}
                    max={data.maxAmount}
                    value={amount}
                    labels={{ [data.minAmount]: `${data.minAmount.toLocaleString("en-US", { style: "currency", currency: "USD" })}`, [data.maxAmount]: `${data.maxAmount.toLocaleString("en-US", { style: "currency", currency: "USD" })}` }}
                    orientation="horizontal"
                    tooltip={false}
                    step={100}
                    onChangeComplete={simulate}
                    onChange={(val) => setAmount(val)}
                  />
                </div>
                <hr style={{ width: "100%", border: "0.5px solid #737373", opacity: 0 }} />
                {/* <div className='title-winput'>
                                    <p>Plazo</p>
                                    <div className="slider-input-wrapper">
                                        <input className="slider-input" type="text" value={data.term} readOnly disabled/>
                                        <span style={{fontSize: '0.7rem'}} className="slider-input-unit">{data.frequency === 2 ? ' quincenas' : ' semanas'}</span>
                                    </div>
                                </div> */}
                <hr style={{ width: "100%", border: "0.5px solid #737373" }} />
                <div className="title-winput">
                  <p>Plazo</p>
                  <div className="slider-input-wrapper">
                    <input className="slider-input" type="text" value={plazo} readOnly />
                    <span style={{ fontSize: "0.7rem" }} className="slider-input-unit">
                      {periodicidad === 3 ? " días" : " días"}
                    </span>
                  </div>
                </div>
                <div className="slider">
                  <div style={{ top: "0.4rem" }} className="slider-steps">
                    <span className="step"></span>
                    <span className="step" style={{ marginLeft: `${stepWidth}px` }}></span>
                    <span className="step" style={{ marginLeft: `${stepWidth}px` }}></span>
                    <span className="step" style={{ marginLeft: `${stepWidth}px` }}></span>
                    <span className="step" style={{ marginLeft: `${stepWidth}px` }}></span>
                    <span className="step" style={{ marginLeft: `${stepWidth}px` }}></span>
                    <span className="step" style={{ marginLeft: `${stepWidth}px` }}></span>
                    <span className="step" style={{ marginLeft: `${stepWidth}px` }}></span>
                    <span className="step" style={{ marginLeft: `${stepWidth}px` }}></span>
                    <span className="step" style={{ marginLeft: `${stepWidth}px` }}></span>
                    <span className="step" style={{ marginLeft: `${stepWidth}px` }}></span>
                    <span className="step" style={{ marginLeft: `${stepWidth}px` }}></span>
                    <span className="step" style={{ marginLeft: `${stepWidth}px` }}></span>
                    <span className="step" style={{ marginLeft: `${stepWidth}px` }}></span>
                    <span className="step" style={{ marginLeft: `${stepWidth}px` }}></span>
                    <span className="step" style={{ marginLeft: `${stepWidth}px` }}></span>
                    <span className="step" style={{ marginLeft: `${stepWidth}px` }}></span>
                    <span className="step" style={{ marginLeft: `${stepWidth}px` }}></span>
                    <span className="step" style={{ marginLeft: `${stepWidth}px` }}></span>
                    <span className="step" style={{ marginLeft: `${stepWidth}px` }}></span>
                    <span className="step" style={{ marginLeft: `${stepWidth}px` }}></span>
                    <span className="step" style={{ marginLeft: `${stepWidth}px` }}></span>
                    <span className="step" style={{ marginLeft: `${stepWidth}px` }}></span>
                    <span className="step" style={{ marginLeft: `${stepWidth}px` }}></span>
                    <span className="step" style={{ marginLeft: `${stepWidth}px` }}></span>
                  </div>
                  <Slider min={data.minPlazo} max={data.maxPlazo} value={plazo} labels={{ [data.maxPlazo]: `${data.maxPlazo}`, [data.minPlazo]: `${data.minPlazo}` }} orientation="horizontal" tooltip={false} step={data.step} onChangeComplete={simulate} onChange={(val) => setPlazo(val)} />
                </div>
                <div className="more-btn-amount-group">
                  <div className="more-group-left">
                    <p>Tu monto total a pagar sería:</p>
                    <p className="more-total">
                      <strong>{(amount + interest).toLocaleString("en-US", { style: "currency", currency: "USD" })} MXN</strong>
                    </p>
                  </div>
                  <div className="more-group-right">
                    <span onClick={getLoan} className="btn more-button">
                      {loadingDone ? <BallClipRotate loading color="white" /> : "SOLICÍTALO YA"}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="right-monto-option">
              <div className="new-loan">
                <div className="amount-calculator-info">
                  <div className="liquidate-resume modal-no-pad">
                    <div className="liquidate-values no-under-border liquidate-title">
                      <p className="bold-type">DETALLES DEL PAGO</p>
                    </div>
                    <div className="liquidate-values no-under-border">
                      <p>Préstamo</p>
                      <p>
                        {loadingSimulation ? "Cargando..." : amount.toLocaleString("en-US", { style: "currency", currency: "USD" })}
                        <small> MXN</small>
                      </p>
                    </div>
                    <hr style={{ margin: "0 auto", width: "90%", height: "0px", color: "rgba(168, 168, 168, .25)", backgroundColor: "#737373" }} />
                    <div className="liquidate-values no-under-border">
                      <p>Plazo</p>
                      <p>
                        {loadingSimulation ? "Cargando..." : plazo} {periodicidad === 2 ? "días" : "días"}
                      </p>
                    </div>
                    <hr style={{ margin: "0 auto", width: "90%", height: "0px", color: "rgba(168, 168, 168, .25)", backgroundColor: "#737373" }} />
                    <div className="liquidate-values no-under-border">
                      <p>Interés</p>
                      <div>
                        <p>
                          {loadingSimulation ? "Cargando..." : interest.toLocaleString("en-US", { style: "currency", currency: "USD" })}
                          <small> MXN</small>
                        </p>
                        <p style={{ fontSize: "0.6rem" }}>IVA incluído</p>
                      </div>
                    </div>
                    <hr style={{ margin: "0 auto", width: "90%", height: "0px", color: "rgba(168, 168, 168, .25)", backgroundColor: "#737373" }} />
                    <div className="liquidate-values no-under-border">
                      <p style={{ textAlign: "left" }}>Comisión por apertura</p>
                      <div>
                        <p>
                          {loadingSimulation ? "Cargando..." : commision.toLocaleString("en-US", { style: "currency", currency: "USD" })}
                          <small> MXN</small>
                        </p>
                        <p style={{ fontSize: "0.6rem" }}>IVA incluído</p>
                      </div>
                    </div>
                    <hr style={{ margin: "0 auto", width: "90%", height: "0px", color: "rgba(168, 168, 168, .25)", backgroundColor: "#737373" }} />
                    <div className="liquidate-values no-under-border">
                      <p>
                        <strong>Monto a devolver</strong>
                      </p>
                      <div>
                        <p>
                          <strong>
                            {loadingSimulation ? "Cargando..." : firstPayment.toLocaleString("en-US", { style: "currency", currency: "USD" })}
                            <small> MXN</small>
                          </strong>
                        </p>
                        <p style={{ fontSize: "0.6rem" }}>IVA incluído</p>
                      </div>
                    </div>
                    <hr style={{ margin: "0 auto", width: "90%", height: "0px", color: "rgba(168, 168, 168, .25)", backgroundColor: "#737373" }} />
                    <div className="liquidate-values">
                      <p>
                        <strong>Fecha de pago</strong>
                      </p>
                      <p>
                        <strong>{loadingSimulation ? "Cargando..." : momentEs(data.paymentDate).format("D/MMM/Y")}</strong>
                      </p>
                    </div>
                  </div>
                  <span onClick={getLoan} className="btn more-button-mobile">
                    {loadingDone ? <BallClipRotate loading color="white" /> : "SOLICÍTALO YA"}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="cat-prom">
            <span>Fecha de vencimiento del préstamo es {loadingSimulation ? "Cargando..." : plazo} días a partir de la fecha de su expedición efectiva</span>
            <br />
            <span className="cat-prom-bold">El monto expresado aquí incluye el total de su préstamo.</span>
          </div>
        </div>
      )}
    </>
  );
};

export default withRouter(More);
