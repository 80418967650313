import axios from "axios";
import { SHA256 } from "crypto-js";
import md5 from "crypto-js/md5";
import publicIp from "public-ip";
import cookie from "react-cookies";

const getApiKey = async () => {
  const today = new Date();
  const dd = String(today.getDate()).padStart(2, "0");
  const mm = String(today.getMonth() + 1).padStart(2, "0"); // Enero es 0
  const yyyy = today.getFullYear();
  const hashText = yyyy + mm + dd + ".V1vu52o2!";
  const hash = String(md5(hashText));
  const xApiKey = hash + "b0251a028d020e0834b68e7d3456c708";

  return { "X-API-Key": xApiKey };
};

export const ID_SUBSTATUS_LEAD = "1";
export const ID_SUBSTATUS_PERSONAL_INFORMATION = "180";
export const ID_SUBSTATUS_EMPLOYMENT_DATA = "181";

//const apiUrl = process.env.REACT_APP_ENV !== "dev" ? "https://tk4f.mx/vivus/traceidentityservice/api" : "https://tekdfi-stage.com.mx/stagevivus/TraceIdentityService/api";
const apiUrl = process.env.REACT_APP_ENV !== "dev" ? "https://tk4f.mx/vivus/tracesecureAPI/api" : "https://tekdfi-stage.com.mx/stagevivus/TraceSecureAPI/api";

// SECURITY //
export const getTokenReq = async () => {
  const data = {
    username: process.env.REACT_APP_ENV !== "dev" ? "Fr0nt3ndu53r" : "Fr0ntV1vu5",
    password: process.env.REACT_APP_ENV !== "dev" ? "5M)_Rt_Dq#ICi{zVONpKFvQdLx" : "b2dbb203bb33dc419d57ad540f866812",
  };
  return axios
    .post(`${apiUrl}/Security/RequestToken`, data, {
      headers: await generateHeaders(data),
    })
    .then((token) => {
      return token;
    })
    .catch((err) => {
      return err;
    });
};

const sleep = async (ms) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

const shuffleArray = async (array) => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
};

const _fn = () => {
  return process.env.REACT_APP_HASH;
};

const generateHeaders = async (data) => {
  const date = new Date();
  const arr = [];
  arr.push(date.getFullYear());
  arr.push(date.getMonth() < 9 ? `0${date.getMonth() + 1}` : date.getMonth() + 1);
  arr.push(date.getDate() < 10 ? `0${date.getDate()}` : date.getDate());
  arr.push(date.getHours() < 10 ? `0${date.getHours()}` : date.getHours());
  arr.push(date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes());
  arr.push(date.getSeconds() < 10 ? `0${date.getSeconds()}` : date.getSeconds());
  if (date.getMilliseconds() < 10) {
    arr.push(`000${date.getMilliseconds()}`);
  } else if (date.getMilliseconds() < 100) {
    arr.push(`00${date.getMilliseconds()}`);
  } else if (date.getMilliseconds() < 1000) {
    arr.push(`0${date.getMilliseconds()}`);
  } else {
    arr.push(`${date.getMilliseconds()}`);
  }

  shuffleArray(arr);

  const hd001 = arr.reduce((pv, cv) => cv + pv + "", "");

  const ip = sessionStorage.ip ? sessionStorage.ip : await publicIp.v4();
  if (ip) {
    sessionStorage.setItem("ip", ip);
  } else {
    sessionStorage.setItem("Unable to get IP address");
  }

  const hd002Unencrypted = `${hd001}|${JSON.stringify(data)}|${_fn()}|${ip}`;
  const hd002 = SHA256(hd002Unencrypted).toString();
  return { HD001: hd001, HD002: hd002 };
};

export const getToken = async () => {
  // Desde que los tokens JWT expiran, pedimos otro 30 segundos desde la asignación
  let expired;

  // while (requestingToken === "true") {
  //   await sleep(500);
  //   requestingToken = sessionStorage.getItem("requestingToken");
  // }

  let currentToken = localStorage.getItem("currentJWTToken");
  let setTime = localStorage.getItem("JWTAssignTime");

  if (setTime) {
    setTime = parseInt(setTime);
    const currentTime = new Date().getTime();
    const elapsedTime = currentTime - setTime;
    if (elapsedTime > 30000) {
      expired = true;
    } else {
      expired = false;
    }
  } else {
    expired = true;
  }

  if (!currentToken || expired) {
    // Usamos await porque algunas partes del código existente no manejan el promise.
    let newToken;
    try {
      newToken = await getTokenReq();
    } catch (e) {}

    if (newToken instanceof Error) {
      return null;
    }
    const assignTime = new Date().getTime();

    let stringified = assignTime.toString();
    localStorage.setItem("JWTAssignTime", stringified);

    stringified = JSON.stringify(newToken);
    localStorage.setItem("currentJWTToken", stringified);
    cookie.save("token", newToken.data.token, { maxAge: 60 * 20 });
    return newToken;
  } else {
    currentToken = JSON.parse(currentToken);
    return currentToken;
  }
};

// PORTFOLIO
export const getCustomerBalance = async (data, token) => {
  const apiKey = getApiKey();
  return axios
    .post(`${apiUrl}/Portfolio/GetCustomerBalance`, { ...data }, { headers: { Authorization: `Bearer ${token}`, ...apiKey, ...(await generateHeaders({ ...data })) } })
    .then((balance) => balance)
    .catch((err) => err);
};

export const getAccountStatement = async (data, token) => {
  const apiKey = getApiKey();
  return axios
    .post(`${apiUrl}/Portfolio/GetAccountStatement`, { ...data }, { headers: { Authorization: `Bearer ${token}`, ...apiKey, ...(await generateHeaders({ ...data })) } })
    .then((accStatement) => accStatement)
    .catch((err) => err);
};

export const getOXXOPaymentReference = async (data, token) => {
  const apiKey = getApiKey();
  return axios
    .post(`${apiUrl}/Portfolio/GetOXXOPaymentReference`, { ...data }, { headers: { Authorization: `Bearer ${token}`, ...apiKey, ...(await generateHeaders({ ...data })) } })
    .then((oxxoRef) => oxxoRef)
    .catch((err) => err);
};

// Get Oxxo payment reference without triggering email delivery
export const getOXXOBEPaymentReference = async (data, token) => {
  const apiKey = getApiKey();
  return axios
    .post(`${apiUrl}/Portfolio/GetOXXOBEPaymentReference`, { ...data }, { headers: { Authorization: `Bearer ${token}`, ...apiKey, ...(await generateHeaders({ ...data })) } })
    .then((oxxoRef) => oxxoRef)
    .catch((err) => err);
};

export const getBankPaymentReference = async (data, token) => {
  const apiKey = getApiKey();
  return axios
    .post(`${apiUrl}/Portfolio/GetBankPaymentReference`, { ...data }, { headers: { Authorization: `Bearer ${token}`, ...apiKey, ...(await generateHeaders({ ...data })) } })
    .then((bankRef) => bankRef)
    .catch((err) => err);
};

export const getPaymentsDetail = async (data, token) => {
  const apiKey = getApiKey();
  return axios
    .post(`${apiUrl}/Portfolio/GetPaymentsDetail`, { ...data }, { headers: { Authorization: `Bearer ${token}`, ...apiKey, ...(await generateHeaders({ ...data })) } })
    .then((paymentDet) => paymentDet)
    .catch((err) => err);
};

// SIMNULATOR //
export const getConfiguration = async (idProduct, token, idCustomer = null) => {
  const apiKey = getApiKey();
  return axios
    .post(`${apiUrl}/Simulator/GetConfiguration`, { idProduct, idCustomer }, { headers: { Authorization: `Bearer ${token}`, ...apiKey, ...(await generateHeaders({ idProduct, idCustomer })) } })
    .then((configuration) => configuration)
    .catch((err) => err);
};

export const getSimulation = async (idProduct, amount = 0, frequency, term, idClient, token) => {
  const apiKey = getApiKey();
  return axios
    .post(`${apiUrl}/Simulator/GetSimulation`, { idProduct, amount, frequency, term, idClient }, { headers: { Authorization: `Bearer ${token}`, ...apiKey, ...(await generateHeaders({ idProduct, amount, frequency, term, idClient })) } })
    .then((simulation) => simulation)
    .catch((err) => err);
};

export const getProposal = async (idProduct, idCustomer, token) => {
  const apiKey = getApiKey();
  return axios
    .post(`${apiUrl}/Simulator/GetProposal`, { idProduct, idCustomer }, { headers: { Authorization: `Bearer ${token}`, ...apiKey, ...(await generateHeaders({ idProduct, idCustomer })) } })
    .then((proposal) => proposal)
    .catch((err) => err);
};

export const saveProposal = async (idProduct, idCustomer, amount, frequency, term, token) => {
  const apiKey = getApiKey();
  return axios
    .post(`${apiUrl}/Simulator/SaveProposal`, { idProduct, idCustomer, amount, frequency, term }, { headers: { Authorization: `Bearer ${token}`, ...apiKey, ...(await generateHeaders({ idProduct, idCustomer, amount, frequency, term })) } })
    .then((proposal) => proposal)
    .catch((err) => err);
};

// QUIESTIONNAIRE
export const getQuestionnaire = async (data, token) => {
  const apiKey = getApiKey();
  return axios.post(`${apiUrl}/Questionnaire/GetQuestionnaire`, { ...data }, { headers: { Authorization: `Bearer ${token}`, ...apiKey, ...(await generateHeaders({ ...data })) } }).then((questions) => questions);
  // .catch(err => err)
};

export const answerQuestionnaire = async (data, token) => {
  const apiKey = getApiKey();
  return axios
    .post(`${apiUrl}/Questionnaire/AnswerQuestionnaire`, { ...data }, { headers: { Authorization: `Bearer ${token}`, ...apiKey, ...(await generateHeaders({ ...data })) } })
    .then((answers) => answers)
    .catch((err) => err);
};

// RISK engine
export const getRiskResult = async (data, token) => {
  const apiKey = getApiKey();
  return axios.post(`${apiUrl}/Risk/GetRiskResult`, { ...data }, { headers: { Authorization: `Bearer ${token}`, ...apiKey, ...(await generateHeaders({ ...data })) }, timeout: 60000 }).then((risk) => risk);
  // .catch(err => err)
};

// IVR
export const getCustomerByPhone = async (phone, token) => {
  const apiKey = getApiKey();
  return axios
    .post(`${apiUrl}/IVR/GetCustomerByPhone`, { phone }, { headers: { Authorization: `Bearer ${token}`, ...apiKey, ...(await generateHeaders({ phone })) } })
    .then((customer) => customer)
    .catch((err) => err);
};

//  ECM  //
export const getContract = async (idProduct, idCustomer, token) => {
  const apiKey = getApiKey();
  return axios
    .post(`${apiUrl}/ECM/GetContract`, { idProduct, idCustomer, returnPDF: false }, { headers: { Authorization: `Bearer ${token}`, ...apiKey, ...(await generateHeaders({ idProduct, idCustomer, returnPDF: false })) } })
    .then((contract) => contract)
    .catch((err) => err);
};

export const getFilledContract = async (data, token) => {
  const apiKey = getApiKey();
  return axios
    .post(`${apiUrl}/ECM/GetFilledContract`, { ...data, returnPDF: true }, { headers: { Authorization: `Bearer ${token}`, ...apiKey, ...(await generateHeaders({ ...data, returnPDF: true })) } })
    .then((contract) => contract)
    .catch((err) => err);
};

export const getTermsAndConditions = async (idProduct, idCustomer, token) => {
  const apiKey = getApiKey();
  return axios
    .post(`${apiUrl}/ECM/GetTermsAndConditions`, { idProduct, idCustomer, returnPDF: false }, { headers: { Authorization: `Bearer ${token}`, ...apiKey, ...(await generateHeaders({ idProduct, idCustomer, returnPDF: false })) } })
    .then((terms) => terms)
    .catch((err) => err);
};

export const getFinancialEducation = async (idProduct, idCustomer, token) => {
  const apiKey = getApiKey();
  return axios
    .post(`${apiUrl}/ECM/GetFinancialEducation`, { idProduct, idCustomer, returnPDF: false }, { headers: { Authorization: `Bearer ${token}`, ...apiKey, ...(await generateHeaders({ idProduct, idCustomer, returnPDF: false })) } })
    .then((education) => education)
    .catch((err) => err);
};

export const getFile = async (data, token) => {
  const apiKey = getApiKey();
  return axios
    .post(`${apiUrl}/ECM/GetFile`, { ...data }, { headers: { Authorization: `Bearer ${token}`, ...apiKey, ...(await generateHeaders({ ...data })) } })
    .then((file) => file)
    .catch((err) => err);
};

export const uploadFile = async (data, token) => {
  const apiKey = getApiKey();
  return axios
    .post(`${apiUrl}/ECM/UploadFile`, { ...data }, { headers: { Authorization: `Bearer ${token}`, ...apiKey, ...(await generateHeaders({ ...data })) } })
    .then((file) => file)
    .catch((err) => err);
};

//  CUSTOMER SECURITY
export const login = async (data, token) => {
  const apiKey = getApiKey();
  return axios
    .post(`${apiUrl}/CustomerSecurity/LogInCustomer`, { ...data }, { headers: { Authorization: `Bearer ${token}`, ...apiKey, ...(await generateHeaders({ ...data })) } })
    .then((loggedCustomer) => loggedCustomer)
    .catch((err) => err);
};

export const recoverPassword = async (data, token) => {
  const apiKey = getApiKey();
  return axios
    .post(`${apiUrl}/CustomerSecurity/RecoverPassword`, { ...data }, { headers: { Authorization: `Bearer ${token}`, ...apiKey, ...(await generateHeaders({ ...data })) } })
    .then((recoverPassword) => recoverPassword)
    .catch((err) => err);
};

export const checkCode = async (data, token) => {
  const apiKey = getApiKey();
  return axios
    .post(`${apiUrl}/CustomerSecurity/CheckCode`, { ...data }, { headers: { Authorization: `Bearer ${token}`, ...apiKey, ...(await generateHeaders({ ...data })) } })
    .then((checkCode) => checkCode)
    .catch((err) => err);
};

export const changePassword = async (data, token) => {
  const apiKey = getApiKey();
  return axios
    .post(`${apiUrl}/CustomerSecurity/ChangePassword`, { ...data }, { headers: { Authorization: `Bearer ${token}`, ...apiKey, ...(await generateHeaders({ ...data })) } })
    .then((changePass) => changePass)
    .catch((err) => err);
};

export const sendCodeByNewEMail = async (data, token) => {
  const apiKey = getApiKey();

  return axios
    .post(`${apiUrl}/CustomerSecurity/SendCodeByNewEMail`, { ...data }, { headers: { Authorization: `Bearer ${token}`, ...apiKey, ...(await generateHeaders({ ...data })) } })
    .then((code) => code)
    .catch((err) => err);
};

export const sendCodeByNewSMS = async (data, token) => {
  const apiKey = getApiKey();

  return axios
    .post(`${apiUrl}/CustomerSecurity/SendCodeByNewSMS`, { ...data }, { headers: { Authorization: `Bearer ${token}`, ...apiKey, ...(await generateHeaders({ ...data })) } })
    .then((code) => code)
    .catch((err) => err);
};

export const sendCodeByNewWA = async (data, token) => {
  const apiKey = getApiKey();

  return axios
    .post(`${apiUrl}/CustomerSecurity/SendCodeByNewSMS`, { ...data }, { headers: { Authorization: `Bearer ${token}`, ...apiKey, ...(await generateHeaders({ ...data })) } })
    .then((code) => code)
    .catch((err) => err);
};

export const checkCustomerToken = async (data, token) => {
  const apiKey = getApiKey();
  return axios
    .post(`${apiUrl}/CustomerSecurity/CheckCustomerToken`, { ...data }, { headers: { Authorization: `Bearer ${token}`, ...apiKey, ...(await generateHeaders({ ...data })) } })
    .then((data) => data)
    .catch((err) => err);
};

// CUSTOMER CARE
export const changeCustomerEmail = async (data, token) => {
  const apiKey = getApiKey();
  return axios
    .post(`${apiUrl}/CustomerCare/ChangeCustomerEmail`, { ...data }, { headers: { Authorization: `Bearer ${token}`, ...apiKey, ...(await generateHeaders({ ...data })) } })
    .then((code) => code)
    .catch((err) => err);
};

export const changeCustomerMobile = async (data, token) => {
  const apiKey = getApiKey();
  return axios
    .post(`${apiUrl}/CustomerCare/ChangeCustomerMobile`, { ...data }, { headers: { Authorization: `Bearer ${token}`, ...apiKey, ...(await generateHeaders({ ...data })) } })
    .then((code) => code)
    .catch((err) => err);
};

export const requestExtension = async (data, token) => {
  const apiKey = getApiKey();
  return axios
    .post(`${apiUrl}/CustomerCare/RequestExtension`, { ...data }, { headers: { Authorization: `Bearer ${token}`, ...apiKey, ...(await generateHeaders({ ...data })) } })
    .then((extension) => extension)
    .catch((err) => err);
};

export const requestAdditionalAmount = async (data, token) => {
  const apiKey = getApiKey();
  return axios.post(`${apiUrl}/CustomerCare/RequestAdditionalAmount`, { ...data }, { headers: { Authorization: `Bearer ${token}`, ...apiKey, ...(await generateHeaders({ ...data })) } }).then((additional) => additional);
};
export const changeCLABE = async (data, token) => {
  const apiKey = getApiKey();
  return axios.post(`${apiUrl}/CustomerCare/ChangeCLABEAccount`, { ...data }, { headers: { Authorization: `Bearer ${token}`, ...apiKey, ...(await generateHeaders({ ...data })) } }).then((clabe) => clabe);
};
export const changeDebit = async (data, token) => {
  const apiKey = getApiKey();
  return axios.post(`${apiUrl}/CustomerCare/ChangeDebitCard`, { ...data }, { headers: { Authorization: `Bearer ${token}`, ...apiKey, ...(await generateHeaders({ ...data })) } }).then((debit) => debit);
};
//  CUSTOMER //
export const getCustomerByMail = async (idProduct, eMail, token) => {
  const apiKey = getApiKey();
  return axios
    .post(`${apiUrl}/Customer/GetCustomerByMail`, { idProduct, eMail }, { headers: { Authorization: `Bearer ${token}`, ...apiKey, ...(await generateHeaders({ idProduct, eMail })) } })
    .then((customer) => customer)
    .catch((err) => err);
};

export const getStatusReq = async (idProduct, idCustomer, isNIP, token) => {
  const apiKey = getApiKey();
  return axios
    .post(`${apiUrl}/Customer/GetStatus`, { idProduct, idCustomer, isNIP }, { headers: { Authorization: `Bearer ${token}`, ...apiKey, ...(await generateHeaders({ idProduct, idCustomer, isNIP })) } })
    .then((status) => {
      sessionStorage.setItem("lastSubStatus", status.data.idSubStatus);
      return status;
    })
    .catch((err) => err);
};

export const getStatus = async (idProduct, idCustomer, isNIP, token) => {
  let currentStatus = sessionStorage.getItem("currentStatus");
  let setTime = sessionStorage.getItem("StatusAssignTime");

  // Para reducir el número de peticiones
  const expirationTime = 400;
  let expired;
  if (setTime) {
    setTime = parseInt(setTime);
    const currentTime = new Date().getTime();
    const elapsedTime = currentTime - setTime;
    if (elapsedTime > expirationTime) {
      expired = true;
    } else {
      expired = false;
    }
  } else {
    expired = true;
  }

  if (!currentStatus || expired) {
    // console.log("################ GETTING NEW STATUS ################");
    // Usamos await porque algunas partes del código existente no manejan el promise.
    const newStatus = await getStatusReq(idProduct, idCustomer, isNIP, token);
    const assignTime = new Date().getTime();

    let stringified = assignTime.toString();
    sessionStorage.setItem("StatusAssignTime", stringified);
    stringified = JSON.stringify(newStatus);
    sessionStorage.setItem("currentStatus", stringified);
    return newStatus;
  } else {
    currentStatus = JSON.parse(currentStatus);
    return currentStatus;
  }
};

export const sendCodeByEMail = async (data, token) => {
  const apiKey = getApiKey();
  return axios
    .post(`${apiUrl}/Customer/SendCodeByEMail`, { ...data }, { headers: { Authorization: `Bearer ${token}`, ...apiKey, ...(await generateHeaders({ ...data })) } })
    .then((code) => code)
    .catch((err) => err);
};

export const sendCodeBySMS = async (data, token) => {
  const apiKey = getApiKey();
  if (process.env.REACT_APP_SITE === "mivivus") {
    return axios.post(`${apiUrl}/Customer/SendCodeBySMS`, { ...data }, { headers: { Authorization: `Bearer ${token}`, ...apiKey, ...(await generateHeaders({ ...data })) } });
  } else {
    return axios.post(`${apiUrl}/Customer/SendCodeBySMS`, { ...data }, { headers: { Authorization: `Bearer ${token}`, ...apiKey, ...(await generateHeaders({ ...data })) } });
  }
};

export const sendCodeByWA = async (data, token) => {
  const apiKey = getApiKey();
  return axios
    .post(`${apiUrl}/Customer/sendCodeBySMS`, { ...data }, { headers: { Authorization: `Bearer ${token}`, ...apiKey, ...(await generateHeaders({ ...data })) } })
    .then((code) => code)
    .catch((err) => err);
};

export const checkSecurityCode = async (data, token) => {
  const apiKey = getApiKey();
  return axios.post(`${apiUrl}/Customer/CheckSecurityCode`, { ...data }, { headers: { Authorization: `Bearer ${token}`, ...apiKey, ...(await generateHeaders({ ...data })) } });
};

export const setContractAuthorization = async (data, token) => {
  const apiKey = getApiKey();
  return axios.post(`${apiUrl}/Customer/SetContractAuthorization`, { ...data }, { headers: { Authorization: `Bearer ${token}`, ...apiKey, ...(await generateHeaders({ ...data })) } }).then((authorized) => authorized);
};
export const setBureauAuthorization = async (data, token) => {
  const apiKey = getApiKey();
  return axios
    .post(`${apiUrl}/Customer/SetBureauAuthorization`, { ...data }, { headers: { Authorization: `Bearer ${token}`, ...apiKey, ...(await generateHeaders({ ...data })) } })
    .then((auth) => auth)
    .catch((err) => err);
};

export const getDirectDebitInformation = async (data, token) => {
  const apiKey = getApiKey();
  return axios
    .post(`${apiUrl}/Customer/GetDirectDebitInformation`, { ...data }, { headers: { Authorization: `Bearer ${token}`, ...apiKey, ...(await generateHeaders({ ...data })) } })
    .then((debitAuth) => debitAuth)
    .catch((err) => err);
};

export const setDirectDebitAuthorization = async (data, token) => {
  const apiKey = getApiKey();
  return axios.post(`${apiUrl}/Customer/SetDirectDebitAuthorization`, { ...data }, { headers: { Authorization: `Bearer ${token}`, ...apiKey, ...(await generateHeaders({ ...data })) } }).then((debitAuth) => debitAuth);
};
// REGISTER
export const getRegistration = async (idProduct, idCustomer, eMail, token) => {
  const apiKey = getApiKey();
  return axios
    .post(`${apiUrl}/Customer/GetRegistration`, { idProduct, idCustomer, eMail }, { headers: { Authorization: `Bearer ${token}`, ...apiKey, ...(await generateHeaders({ idProduct, idCustomer, eMail })) } })
    .then((register) => register)
    .catch((err) => err);
};

export const setRegistration = async (data, token) => {
  const apiKey = getApiKey();
  return axios
    .post(`${apiUrl}/Customer/SetRegistration`, { ...data }, { headers: { Authorization: `Bearer ${token}`, ...apiKey, ...(await generateHeaders({ ...data })) } })
    .then((register) => register)
    .catch((err) => err);
};

// PERSONAL DATA
export const getPersonalInformation = async (data, token) => {
  const apiKey = getApiKey();
  return axios
    .post(`${apiUrl}/Customer/GetPersonalInformation`, { ...data }, { headers: { Authorization: `Bearer ${token}`, ...apiKey, ...(await generateHeaders({ ...data })) } })
    .then((personalInfo) => personalInfo)
    .catch((err) => err);
};

export const setPersonalInformation = async (data, token) => {
  const apiKey = getApiKey();
  return axios.post(`${apiUrl}/Customer/SetPersonalInformation`, { ...data }, { headers: { Authorization: `Bearer ${token}`, ...apiKey, ...(await generateHeaders({ ...data })) } }).then((setPerInfo) => setPerInfo);
  // .catch(err => err)
};

export const setQuickPersonalInformation = async (data, token) => {
  const apiKey = getApiKey();
  return axios.post(`${apiUrl}/Customer/SetQuickPersonalInformation`, { ...data }, { headers: { Authorization: `Bearer ${token}`, ...apiKey, ...(await generateHeaders({ ...data })) } }).then((setPerInfo) => setPerInfo);
  // .catch(err => err)
};

// EMPLOYMENT
export const getEmploymentInformation = async (data, token) => {
  const apiKey = getApiKey();
  return axios
    .post(`${apiUrl}/Customer/GetEmploymentInformation`, { ...data }, { headers: { Authorization: `Bearer ${token}`, ...apiKey, ...(await generateHeaders({ ...data })) } })
    .then((employmentInfo) => employmentInfo)
    .catch((err) => err);
};

export const setEmploymentInformation = async (data, token) => {
  const apiKey = getApiKey();
  return axios
    .post(`${apiUrl}/Customer/SetEmploymentInformation`, { ...data }, { headers: { Authorization: `Bearer ${token}`, ...apiKey, ...(await generateHeaders({ ...data })) } })
    .then((employmentInfo) => employmentInfo)
    .catch((err) => err);
};

// CATALOGS
export const getBanks = async (token) => {
  const apiKey = getApiKey();
  return axios
    .post(`${apiUrl}/Catalog/GetBanks`, {}, { headers: { Authorization: `Bearer ${token}`, ...apiKey, ...(await generateHeaders({})) } })
    .then((banks) => banks)
    .catch((err) => err);
};

export const getCatalogs = async (token) => {
  const apiKey = getApiKey();
  return axios
    .post(`${apiUrl}/Catalog/GetCatalogs`, {}, { headers: { Authorization: `Bearer ${token}`, ...apiKey, ...(await generateHeaders({})) } })
    .then((catalogs) => catalogs)
    .catch((err) => err);
};

export const getSingleCatalog = async (data, token) => {
  const apiKey = getApiKey();
  return axios
    .post(`${apiUrl}/Catalog/GetSingleCatalog`, { ...data }, { headers: { Authorization: `Bearer ${token}`, ...apiKey, ...(await generateHeaders({ ...data })) } })
    .then((catalog) => catalog)
    .catch((err) => err);
};

export const getCatalogByZipCode = async (data, token) => {
  const apiKey = getApiKey();
  return axios
    .post(`${apiUrl}/Catalog/GetCatalogByZipCode`, { ...data }, { headers: { Authorization: `Bearer ${token}`, ...apiKey, ...(await generateHeaders({ ...data })) } })
    .then((zipCatalog) => zipCatalog)
    .catch((err) => err);
};

// MARKETING
export const getAnalytics = async (data, token) => {
  const apiKey = getApiKey();
  return axios
    .post(`${apiUrl}/Marketing/GetGACustomerData`, { ...data }, { headers: { Authorization: `Bearer ${token}`, ...apiKey, ...(await generateHeaders({ ...data })) } })
    .then((analytics) => analytics)
    .catch((err) => err);
};

export const saveSingleUTM = async (data, token) => {
  const apiKey = getApiKey();
  return axios
    .post(`${apiUrl}/Marketing/saveSingleUTM`, { ...data }, { headers: { Authorization: `Bearer ${token}`, ...apiKey, ...(await generateHeaders({ ...data })) } })
    .then((analytics) => analytics)
    .catch((err) => err);
};

export const setIdValidation = async ({ message, identityValidated, userAgent, ip, idCustomer, idProduct, token }) => {
  const apiKey = getApiKey();
  return axios
    .post(`${apiUrl}/Customer/SetIdentityValidation`, { message, identityValidated, userAgent, ip, idCustomer, idProduct }, { headers: { Authorization: `Bearer ${token}`, ...apiKey, ...(await generateHeaders({ message, identityValidated, userAgent, ip, idCustomer, idProduct })) } })
    .then((status) => status)
    .catch((err) => err);
};

export const getDocumentList = async (data, token) => {
  const apiKey = getApiKey();
  return axios
    .post(`${apiUrl}/Risk/GetDocumentList`, { ...data }, { headers: { Authorization: `Bearer ${token}`, ...apiKey, ...(await generateHeaders({ ...data })) } })
    .then((status) => status)
    .catch((err) => err);
};
