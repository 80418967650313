import React from "react";
import "./common.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone, faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { faFacebookF } from "@fortawesome/free-brands-svg-icons";

const whatsappNumbers = ["https://bit.ly/3XEnLix", "http://bit.ly/40pntya", "http://bit.ly/3Jrn5cu", "http://bit.ly/3WQeUJJ"];

const pickWA = (numbers) => {
  const pick = Math.floor(Math.random() * numbers.length);
  return numbers[pick];
};

export const Social = ({ location }) => {
  const scrollBottom = () => {
    window.scrollTo(0, document.body.scrollHeight);
  };

  return (
    <div className="social">
      {/* <a className="whatsapp" rel="noopener noreferrer" target="_blank" href={pickWA(whatsappNumbers)}>
        <img src="/img/whatsapp.png" alt="whatsapp" />
      </a> */}

      <div className="quick-contacts-content">
        <ul>
          <li>
            <FontAwesomeIcon onClick={scrollBottom} className="clickable" icon={faPhone} />
          </li>
          <li>
            <FontAwesomeIcon onClick={scrollBottom} className="clickable" icon={faEnvelope} />
          </li>
          <li>
            <a target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/VivusMexico/">
              <FontAwesomeIcon onClick={scrollBottom} className="clickable" icon={faFacebookF} />
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};
