import React from "react";
import PropTypes from "prop-types";

const Button = ({ children, onClick = () => null, className = "" }) => {
  return (
    <p onClick={onClick} role="button" className={`${className} bg-green-light flex justify-center hover:bg-green-dark transition-all font-bold uppercase text-white p-2 rounded-md cursor-pointer`}>
      {children}
    </p>
  );
};

Button.propTypes = {
  children: PropTypes.element.isRequired,
  onClick: PropTypes.func,
  className: PropTypes.string,
};

Button.defaultValues = {
  onClick: () => null,
  className: "",
};

export default Button;
