import React, { useState, useEffect } from "react";
import "./App.css";
import Routes from "./routes/Routes";
import NavBar from "./components/common/NavBar";
import { Footer } from "./components/common/Footer";
import { Social } from "./components/common/Social";
import { Route, Switch, withRouter } from "react-router-dom";
import TagManager from "react-gtm-module";
import { useMatomo } from "@datapunt/matomo-tracker-react";
import { AvisoDePrivacidad } from "./components/common/footer-links/AvisoDePrivacidad";
import { TerminosCondiciones } from "./components/common/footer-links/TerminosCondiciones";
import { FAQ } from "./components/works/FAQ";
import GeneralContract from "./components/common/GeneralContract";

const tagManagerArgs = {
  gtmId: process.env.REACT_APP_GTM_CONTAINER,
  dataLayerName: "dataLayer",
};

TagManager.initialize(tagManagerArgs);

function App(props) {
  const [location, setLocation] = useState(null);
  const [isLogged, setIsLogged] = useState(false);

  const [loaded] = useState(false);
  const { pushInstruction } = useMatomo();

  useEffect(() => {
    if (window.location.pathname === "/") setLocation("home");
    if (props.location.pathname.includes("/dashboard")) setLocation("dashboard");
    if (props.location.pathname.includes("/login")) setLocation("app");
    // if(props.location.pathname.includes('/blog')) setLocation('blog')
    if (props.location.pathname.includes("/registration")) setLocation("app");
    if (props.location.pathname.includes("/denied")) setLocation("denied");
    if (props.location.pathname.includes("/contenido")) setLocation("contenido");
    if (props.location.pathname.includes("/us")) setLocation("us");
    if (props.location.pathname.includes("/denied")) setLocation("denied");
    if (props.location.pathname.includes("/en-mantenimiento")) setLocation("manteinance");
    if (sessionStorage.getItem("loggedUser")) setIsLogged(true);
    else setIsLogged(false);
  }, [props.location]);

  useEffect(() => {
    if (sessionStorage.loggedUser) {
      const { customerId } = JSON.parse(sessionStorage.loggedUser);
      pushInstruction("setUserId", customerId + "");
    }
  }, [window?.location?.pathname]);

  useEffect(() => {
    try {
      if (sessionStorage.balance) {
        const { idCredit } = JSON.parse(sessionStorage.balance);
        pushInstruction("setCustomVariable", 1, "LOAN_ID", idCredit, "visit");
      }
    } catch (error) {
      console.log(error);
    }
  }, [window?.location?.pathname]);

  return (
    <>
      <Switch>
        {/* RUTAS EN mantenimiento */}
        {/* <Route exact path='/' component={Home}/> */}
        <Route exact path="/mobile/privacidad" component={AvisoDePrivacidad} />
        <Route exact path="/mobile/terminos-y-condiciones" component={TerminosCondiciones} />
        <Route exact path="/mobile/faq" component={FAQ} />
        <Route exact path="/mobile/contract" component={GeneralContract} />
        <Route
          path="/*"
          render={(props) => (
            <div className="App flex flex-col">
              {loaded ? (
                <div></div>
              ) : (
                <>
                  <>
                    <NavBar isLogged={isLogged} location={location} setLocation={setLocation} />
                    <Routes />
                    <Social location={location} />
                  </>
                  <Footer location={location} />
                </>
              )}
            </div>
          )}
        />
      </Switch>
    </>
  );
}

export default withRouter(App);
