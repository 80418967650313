import React, { useState, useEffect, lazy } from "react";
import { getToken, saveSingleUTM } from "../../services/api.js";
import TagManager from "react-gtm-module";
import "./landing.scss";
import { momentEs } from "../../services/moment";
import { Suspense } from "react/cjs/react.production.min.js";
import Cover from "./home/Cover";
import AppBenefits from "./home/AppBenefits.jsx";

const Reasons = lazy(() => import("./home/Reasons"));
const Testimonial = lazy(() => import("./home/Testimonial"));
const Legal = lazy(() => import("./home/Legal"));

const sendUTM = async (utm) => {
  const {
    data: { token },
  } = await getToken();

  saveSingleUTM(
    {
      customerOrigin: document.URL,
      utm,
      customerId: 0,
      productId: 1,
      userAgent: navigator.userAgent,
      clientIP: "0.0.0.0",
    },
    token
  ).then((res) => {});
};

export const Home = (props) => {
  const [, setTempScreen] = useState(null);

  useEffect(() => {
    if (props.location.search === "") sessionStorage.setItem("utm", "/");
    else sessionStorage.setItem("utm", props.location.search);
    const tagManagerArgs = {
      dataLayer: {
        event: "pageChange",
        page: {
          url: "/",
          referrer: sessionStorage.getItem("utm") || "/",
        },
      },
      dataLayerName: "dataLayer",
    };
    TagManager.dataLayer(tagManagerArgs);
    if (!sessionStorage.getItem("utm-sent")) {
      sendUTM(sessionStorage.getItem("utm"));
    }
  }, [props.location.search]);

  useEffect(() => {
    let startDate = momentEs("01/04/2020", "DD/MM/YYYY");
    let endDate = momentEs("30/04/2020", "DD/MM/YYYY");
    if (momentEs().isBetween(startDate, endDate)) return setTempScreen(true);
    return setTempScreen(false);
  }, []);

  return (
    <Suspense fallback={<span>Cargando...</span>}>
      <div className="w-screen h-auto flex flex-col">
        <Cover />
        <Reasons />
        <AppBenefits />
        <Testimonial /> <Legal />
      </div>
    </Suspense>
  );
};
