import React, { useState } from "react";
import { getToken, login, changePassword } from "../../../services/api";
import { BallClipRotate } from "react-pure-loaders";
import Button from "../../common/Button";

const idProduct = 1;

const ChangePassword = ({ setSection }) => {
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [oldPasswordErr, setOldPasswordErr] = useState(false);
  const [newPasswordErr, setNewPasswordErr] = useState(false);
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const checkPassword = async () => {
    if (newPassword.length < 6) return setNewPasswordErr(true);
    if (newPassword.length > 30) return setNewPasswordErr(true);
    if (newPassword.search(/\d/) === -1) return setNewPasswordErr(true);
    if (newPassword.search(/[a-zA-Z]/) === -1) return setNewPasswordErr(true);
    setNewPasswordErr(false);
    setLoading(true);
    let response = await getToken();
    let validToken = response.data.token;
    let coords = sessionStorage.getItem("coords");
    if (!coords) coords = "Location blocked";
    let myIp = sessionStorage.getItem("ip");
    if (!myIp) myIp = "192.168.1.254";
    let user = JSON.parse(sessionStorage.getItem("loggedUser"));
    const data = {
      idProduct,
      userName: user.eMail,
      password: oldPassword,
      ipAddress: myIp,
      userAgent: navigator.userAgent,
      coordinates: coords,
    };
    login(data, validToken).then((res) => {
      if (res.status === 200) {
        const changeData = {
          idProduct,
          eMail: user.eMail,
          userName: user.eMail,
          newPassword,
          ipAddress: myIp,
          userAgent: navigator.userAgent,
          coordinates: coords,
          password: newPassword,
        };
        return changePassword(changeData, validToken).then((changed) => {
          if (changed.status === 200) {
            setSuccess(true);
            setLoading(false);
          }
          setError(true);
          setLoading(false);
        });
      }
      setLoading(false);
      setOldPasswordErr(true);
    });
  };

  return (
    <div>
      {success ? (
        <div className="flex flex-col gap-4">
          <h4 className="text-2xl font-bold">Contraseña actualizada correctamente</h4>
          <div onClick={() => setSection("home")} className="underline text-green-dark cursor-pointer">
            <p>Regresar</p>
          </div>
        </div>
      ) : (
        <>
          <div className="flex flex-col gap-4">
            <h3 className="text-2xl">Cambiar contraseña</h3>
            <div className={`flex flex-col gap-2`}>
              <label className="font-bold">Ingresar contraseña anterior</label>
              <div className="relative w-full flex items-center lg:w-96">
                <input className="h-10 w-full bg-white rounded-md px-4 text-gray-800 border border-gray-200 shadow-sm " onChange={(e) => setOldPassword(e.target.value)} value={oldPassword} type={showOldPassword ? "text" : "password"} />
                <img className="absolute right-4 cursor-pointer my-0 mx-auto" src={showOldPassword ? "/img/hidden.png" : "/img/view.png"} width="24" onClick={() => setShowOldPassword(!showOldPassword)} alt="eye" />
              </div>
              {oldPasswordErr ? <span className="text-red-500">Contraseña incorrecta.</span> : null}
            </div>

            <div className={`flex flex-col gap-2`}>
              <label className="font-bold">Ingresar nueva contraseña</label>
              <div className="relative w-full flex items-center lg:w-96">
                <input className="h-10 w-full bg-white rounded-md px-4 text-gray-800 border border-gray-200 shadow-sm" onChange={(e) => setNewPassword(e.target.value)} value={newPassword} type={showNewPassword ? "text" : "password"} />
                <img className="absolute right-4 cursor-pointer my-0 mx-auto" src={showNewPassword ? "/img/hidden.png" : "/img/view.png"} width="24" onClick={() => setShowNewPassword(!showNewPassword)} alt="eye" />
              </div>

              {newPasswordErr ? <span className="text-red-500">La contraseña debe tener mínimo 6 caracteres.</span> : null}
              {error ? <span className="text-red-500">Ocurrió un error en el servidor al actualizar tu contraseña, intenta nuevamente.</span> : null}
            </div>

            <Button className={`lg:w-96 text-center`} onClick={checkPassword}>
              {loading ? <BallClipRotate color={"#A8CC46"} loading /> : "CONFIRMAR"}
            </Button>
            <div onClick={() => setSection("home")} className="underline text-green-dark cursor-pointer">
              <p>Cancelar</p>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default ChangePassword;
