import React, { useState, useEffect } from "react";
import "./common.scss";
import { Link } from "react-router-dom";
import cookie from "react-cookies";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faTimes } from "@fortawesome/free-solid-svg-icons";
import { momentEs } from "../../services/moment";
import LinkButton from "./LinkButton";
import { getAnalytics, getToken } from "../../services/api";

const idProduct = 1;

const NavBar = ({ isLogged, location, setLocation }) => {
  const [showMenu, setShowMenu] = useState(false);
  const [showProfile, setShowProfile] = useState(true);
  const [, setTempScreen] = useState(null);
  const [loc, setLoc] = useState("");

  useEffect(() => {
    setLoc(document.location.pathname);
  }, [document.location.href]);

  useEffect(() => {
    const f = async () => {
      let response = await getToken();
      const validToken = response.data.token;
      const idCustomer = JSON.parse(sessionStorage.loggedUser).customerId;
      let {
        data: { returningClient },
      } = await getAnalytics({ idProduct, idCustomer }, validToken);
      console.log(returningClient);
      if (returningClient === "RETURNING") {
        setShowProfile(true);
      } else {
        const ssCurrentStatus = sessionStorage.getItem("currentStatus");
        if (ssCurrentStatus) {
          const {
            data: { idStatus },
          } = JSON.parse(ssCurrentStatus);

          if (!idStatus || idStatus === 1 || idStatus === 8 || idStatus === 4) {
            setShowProfile(false);
          } else {
            setShowProfile(true);
          }
        } else {
          setShowProfile(false);
        }
      }
    };
    f();
  }, [sessionStorage.currentStatus]);

  const logo = process.env.REACT_APP_SITE === "mivivus" ? "/img/navbar/logo-vivus.svg" : "/img/navbar/logo-vivus-mexico.svg";
  useEffect(() => {
    let startDate = momentEs("01/04/2020", "DD/MM/YYYY");
    let endDate = momentEs("30/04/2020", "DD/MM/YYYY");
    if (momentEs().isBetween(startDate, endDate)) return setTempScreen(true);
    return setTempScreen(false);
  }, []);

  return (
    <nav className="flex justify-center z-20 bg-white w-full top-0 shadow-sm">
      <div className="flex w-full justify-between items-center container p-4">
        <Link className="w-5/12 md:w-64" onClick={() => setLocation("home")} to="/">
          <img className="w-full" src={logo} alt="vivus logo" />
        </Link>

        <ul className="justify-end flex-grow text-black gap-x-6 pr-6 hidden md:flex">
          <li>
            <Link className={`hover:text-green-dark text-sm transition-all ${location === "works" ? "text-green-light" : "text-gray-500"}`} onClick={() => setLocation("works")} to="/como-funciona">
              ¿Cómo funciona? {isLogged}
            </Link>
          </li>
          {/* {process.env.REACT_APP_SITE !== "mivivus" && (
            <li>
              <Link className={`hover:text-green-dark text-sm transition-all ${location === "blog" ? "text-green-light" : "text-gray-500"}`} onClick={() => setLocation("blog")} to="/blog">
                Blog
              </Link>
            </li>
          )} */}
          <li>
            <Link className={`hover:text-green-dark text-sm transition-all ${location === "us" ? "text-green-light" : "text-gray-500"}`} onClick={() => setLocation("us")} to="/us">
              Sobre Nosotros
            </Link>
          </li>
          <li>
            <a className={`hover:text-green-dark text-sm transition-all ${location === "us" ? "text-green-light" : "text-gray-500"}`} onClick={() => setLocation("blog")} href="https://www.mivivus.com/blog">
              Blog
            </a>
          </li>
          {isLogged && showProfile && (
            <li>
              <Link className={`hover:text-green-dark text-sm transition-all ${location === "profile" ? "text-green-light" : "text-gray-500"}`} onClick={() => setLocation("profile")} to="/perfil">
                Mis datos
              </Link>
            </li>
          )}
          {isLogged && (
            <li>
              <Link
                className={`hover:text-green-dark text-sm transition-all text-red-500`}
                onClick={() => {
                  setShowMenu(!showMenu);
                  sessionStorage.clear();
                  cookie.remove("token");
                }}
                to="/login"
              >
                Cerrar sesión
              </Link>
            </li>
          )}
        </ul>
        <a
          onClick={() => {
            setLocation("app");
            setShowMenu(false);
          }}
          className="border-2 mx-2 border-green-light hover:border-green-dark hover:text-opacity-80 transition-all font-bold uppercase text-green-dark p-2 rounded-md text-xs hidden lg:flex"
          href="https://play.google.com/store/apps/details?id=mx.app.vivus"
          target="_blank"
        >
          Descarga la app
        </a>

        {!isLogged && (
          <LinkButton
            onClick={() => {
              setLocation("app");
              setShowMenu(false);
            }}
            className="text-xs"
            to="/login"
          >
            ¿Ya eres cliente?
          </LinkButton>
        )}
        <div className="relative md:hidden bg-white">
          <div className="text-gray-500 text-xl">
            <FontAwesomeIcon className={`transition-all`} onClick={() => setShowMenu(!showMenu)} icon={showMenu ? faTimes : faBars} />
          </div>
          <ul className={`top-8 -right-4 p-4 z-30 absolute w-screen shadow-md bg-white transition-all ${showMenu ? "opacity-1" : "opacity-0 pointer-events-none "}`}>
            <li className="p-4">
              <Link
                onClick={() => {
                  setLocation("works");
                  setShowMenu(false);
                }}
                className="text-green-light hover:text-green-dark transition-all"
                to="/como-funciona"
              >
                ¿Cómo funciona?
              </Link>
            </li>
            <li className="p-4">
              <Link
                onClick={() => {
                  setLocation("us");
                  setShowMenu(false);
                }}
                className="text-green-light hover:text-green-dark transition-all"
                to="/us"
              >
                Sobre nosotros
              </Link>
            </li>
            <li className="p-4">
              <a
                onClick={() => {
                  setLocation("blog");
                  setShowMenu(false);
                }}
                className="text-green-light hover:text-green-dark transition-all"
                href="https://www.mivivus.com/blog"
              >
                Blog
              </a>
            </li>
            <li className="p-4">
              <a
                onClick={() => {
                  setLocation("app");
                  setShowMenu(false);
                }}
                className="text-green-dark hover:text-green-light transition-all"
                href="https://play.google.com/store/apps/details?id=mx.app.vivus"
                target="_blank"
              >
                Descarga la app
              </a>
            </li>

            {isLogged && showProfile && (
              <li className="p-4">
                <Link
                  onClick={() => {
                    setLocation("profile");
                    setShowMenu(false);
                  }}
                  to="/perfil"
                  className="text-green-light hover:text-green-dark transition-all"
                >
                  Mis datos
                </Link>
              </li>
            )}
            {isLogged && (
              <li className="p-4">
                <Link
                  onClick={() => {
                    sessionStorage.clear();
                    cookie.remove("token");
                    setLocation("app");
                    setShowMenu(!showMenu);
                  }}
                  to="/login"
                  className="text-green-light hover:text-green-dark transition-all"
                >
                  Cerrar sesión
                </Link>
              </li>
            )}
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default NavBar;
