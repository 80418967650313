import React, { useState } from "react";
import CheckCode from "./email/CheckCode";
import { getToken, sendCodeByNewSMS } from "../../../services/api";
import { BallClipRotate } from "react-pure-loaders";
import Button from "../../common/Button";

const idProduct = 1;

const ChangePhone = ({ history, setSection }) => {
  const [number, setNumber] = useState(null);
  const [numberErr, setNumberErr] = useState("");
  const [validNumber, setValidNumber] = useState(false);
  const [loading, setLoading] = useState(false);

  const checkNumber = async () => {
    if (number.length < 10) return setNumberErr(true);
    let reg = /[0-9]{10}$/;
    if (reg.test(number)) {
      setLoading(true);
      let response = await getToken();
      let validToken = response.data.token;
      let coords = sessionStorage.getItem("coords");
      if (!coords) coords = "Location blocked";
      let myIp = sessionStorage.getItem("ip");
      if (!myIp) myIp = "192.168.1.254";
      let user = JSON.parse(sessionStorage.getItem("loggedUser"));
      const data = {
        newEMail: "",
        newMobile: number,
        userAgent: navigator.userAgent,
        remoteIp: myIp,
        coordinates: coords,
        isNIP: false,
        idCustomer: user.customerId,
        idProduct,
      };

      sendCodeByNewSMS(data, validToken).then((res) => {
        if (res.status === 200) {
          setValidNumber(true);
          setLoading(false);
        }
        setNumberErr(true);
        setLoading(false);
      });
      setNumberErr(false);
      return setValidNumber(true);
    }
    setNumberErr(true);
  };

  return (
    <div>
      {validNumber ? (
        <CheckCode history={history} number={number} setValidNumber={setValidNumber} setSection={setSection} />
      ) : (
        <div className="flex flex-col gap-4">
          <h3 className="text-2xl">Cambiar número celular</h3>

          <div className={`flex flex-col gap-2`}>
            <label className="font-bold">Ingresa tu nuevo número celular</label>
            <input className="h-10 w-full bg-white rounded-md px-4 text-gray-800 border border-gray-200 shadow-sm lg:w-96" maxLength="10" onChange={(e) => setNumber(e.target.value)} value={number} placeholder="Ingresa tu número a 10 dígitos" type="text" />
            {numberErr ? <span className="text-red-500">Número incorrecto</span> : null}
          </div>
          <Button className={`lg:w-96 text-center`} onClick={checkNumber}>
            {loading ? <BallClipRotate color={"#A8CC46"} loading /> : "CAMBIAR NÚMERO DE TELÉFONO CELULAR"}
          </Button>

          <div onClick={() => setSection("home")} className="underline text-green-dark cursor-pointer">
            <p>Cancelar</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default ChangePhone;
