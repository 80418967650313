import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

const LinkButton = ({ children, onClick = () => null, to, className }) => {
  return (
    <Link to={to} onClick={onClick} className={`bg-green-light hover:bg-green-dark transition-all font-bold uppercase text-white p-2 rounded-md ${className}`}>
      {children}
    </Link>
  );
};

LinkButton.propTypes = {
  children: PropTypes.string,
  onClick: PropTypes.func,
  to: PropTypes.string.isRequired,
  className: PropTypes.string,
};

LinkButton.defaultValues = {
  onClick: () => null,
  className: "",
};

export default LinkButton;
