import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt, faMoneyBill, faSearch, faSync } from "@fortawesome/free-solid-svg-icons";

const AppBenefits = () => {
  return (
    <section className="flex flex-col justify-center w-full relative items-center bg-gray-100">
      <div className="container p-4 flex justify-between flex-col lg:flex-row pt-24 pb-24 lg:pb-56 z-10">
        <div className="flex w-full flex-col gap-24">
          <h2 className="text-left text-green-dark text-3xl font-bold">Descarga la app y mantente siempre al tanto</h2>
          <div className="flex">
            <div className="lg:w-2/3 flex flex-col gap-12">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                <div className="flex gap-4 items-center">
                  <FontAwesomeIcon icon={faMoneyBill} width={70} className="text-4xl text-green-dark" />
                  <div className="flex flex-col gap-4 flex-1">
                    <span className="text-green-dark text-2xl font-bold">Solicitar nuevos préstamos</span>
                    <span className="text-gray-500 font-light">Podrás iniciar tu proceso de solicitud ingresando directamente a la app.</span>
                  </div>
                </div>
                <div className="flex gap-4 items-center ">
                  <FontAwesomeIcon icon={faSearch} width={70} className="text-4xl text-green-dark" />
                  <div className="flex flex-col gap-4 flex-1">
                    <span className="text-green-dark text-2xl font-bold">Consulta</span>
                    <span className="text-gray-500 font-light">Revisa información sobre tus préstamos actuales para saber tus pagos pendientes y fechas de vencimiento.</span>
                  </div>
                </div>
                <div className="flex gap-4 items-center ">
                  <FontAwesomeIcon icon={faCalendarAlt} width={70} className="text-4xl text-green-dark" />
                  <div className="flex flex-col gap-4 flex-1">
                    <span className="text-green-dark text-2xl font-bold">Extiende tu fecha de pago</span>
                    <span className="text-gray-500 font-light">Podrás solicitar recorrer tu fecha de pago en caso de que no puedas pagarlo antes de la fecha de vencimiento.</span>
                  </div>
                </div>
                <div className="flex gap-4 items-center ">
                  <FontAwesomeIcon icon={faSync} width={70} className="text-4xl text-green-dark" />
                  <div className="flex flex-col gap-4 flex-1">
                    <span className="text-green-dark text-2xl font-bold">Sincroniza tu cuenta</span>
                    <span className="text-gray-500 font-light">Si ya solicitaste un préstamo con nosotros, podrás utilizar tu misma cuenta para solicitar un nuevo préstamo o consultar información.</span>
                  </div>
                </div>
              </div>
              <div className="flex justify-around w-full">
                <a href="https://play.google.com/store/apps/details?id=mx.app.vivus" target="_blank" rel="noreferrer">
                  <img src={`/img/googleplay.png`} alt="Download on Google Play" width={300} />
                </a>
              </div>
            </div>
            <div className="lg:w-1/3"></div>
          </div>
        </div>
      </div>
      <div className="lg:absolute right-0 bottom-0 h-full lg:w-1/4 xl:w-1/3 flex lg:items-end">
        <img src={`/img/app.png`} alt="Phone vivus app" />
      </div>
    </section>
  );
};

AppBenefits.propTypes = {};
AppBenefits.defaultValues = {};

export default AppBenefits;
