import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Link } from "react-router-dom";
import Calculator from "../../calculator/Calculator";
import "../../../index.css";
import "../../../App.css";

const Cover = () => (
  <section className="w-full flex flex-col items-center lg:flex-row lg:items-start justify-center bg-blend-overlay  lg:bg-blend-lighten lg:bg-cover lg:bg-center xl:pb-12 relative lg:min-h-lgcover xl:min-h-cover">
    <img src="/img/landing/person.webp" alt="logo" width={480} className="absolute bottom-0 hidden translate-x-28 z-20 2xl:block" />
    <img src="/img/landing/person.webp" alt="logo" width={430} className="absolute bottom-0 hidden translate-x-24 z-20 xl:block 2xl:hidden" />
    <img src="/img/landing/person.webp" alt="logo" width={300} className="absolute bottom-0 hidden translate-x-28 lg:block xl:hidden left-8" />
    <div
      className="absolute w-full pointer-events-none h-full hidden lg:block -z-10 bg-cover bg-center bg-gray-300 bg-blend-multiply blur-sm"
      style={process.env.REACT_APP_SITE === "mivivus" ? { backgroundImage: "url(/img/landing/landing.jpg)" } : { backgroundImage: "url(/img/landing/landing.jpg)" }}
    />
    <div className="p-4 bg-gray-100 flex items-center lg:hidden gap-4 w-full max-w-lg ">
      <span className="font-bold w-3/4">Los servicios 4FINANCE, S.A. DE C.V., SOFOM, E.N.R. (“4FINANCE”) son debidamente regulados por CONDUSEF</span>
      <Link to="/informacion-regulatoria" className="text-green-dark underline font-bold w-1/4">
        Ver más
      </Link>
    </div>
    <div className="container self-center flex flex-col lg:flex-row py-4 gap-8 justify-center lg:justify-between lg:items-start xl:items-center px-4 -pt-4 z-10 lg:py-8 ">
      <div className="flex flex-col max-w-2xl gap-4">
        <h1 className="text-3xl lg:text-4xl xl:text-6xl font-bold lg:text-white text-green-dark">Solicita tu crédito ahora</h1>
        <h2 className="text-2xl text-green-light lg:hidden">Obtén hasta $2,000 pesos en tu primer crédito.</h2>
        <ul className="hidden lg:block">
          <li className="text-xl lg:text-2xl lg:text-white text-green-light flex gap-4 items-center my-2">
            <FontAwesomeIcon icon={faCheck} />
            <span>
              Obtén hasta <strong className="font-bold text-green-light"> $2,000</strong> en tu primer crédito.
            </span>
          </li>
          <li className="text-xl lg:text-2xl lg:text-white text-green-light flex gap-4 items-center my-2">
            <FontAwesomeIcon icon={faCheck} />
            <span>
              Todo el proceso es <strong className="font-bold text-green-light"> en línea </strong> y sin papeleo.
            </span>
          </li>
          <li className="text-xl lg:text-2xl lg:text-white text-green-light flex gap-4 items-center my-2">
            <FontAwesomeIcon icon={faCheck} />
            <span>Con total transparencia y seguridad.</span>
          </li>
          <li className="text-xl lg:text-2xl lg:text-white text-green-light flex gap-4 items-center my-2 max-w-xl">
            <FontAwesomeIcon icon={faCheck} />
            <span>
              Si ya eres cliente Vivus, puedes solicitar hasta <strong className="font-bold text-green-light">$8,000</strong>,{" "}
              <Link to="/login" className="underline">
                accediendo a tu perfil
              </Link>
              .
            </span>
          </li>
        </ul>
        <div className="p-4 bg-white border-4 border-green-dark lg:flex items-center hidden gap-4 w-full max-w-lg rounded-lg bg-opacity-90">
          <img src="/img/verified.png" alt="Verified icon" className="w-12" />
          <div className="flex flex-col gap-2">
            <span className="font-bold">Los servicios 4FINANCE, S.A. DE C.V., SOFOM, E.N.R. (“4FINANCE”) son debidamente regulados por CONDUSEF</span>
            <Link to="/informacion-regulatoria" className="text-green-dark underline font-bold">
              Ver más
            </Link>
          </div>
        </div>
      </div>
      <Calculator home={true} />
    </div>
  </section>
);

export default Cover;
