import React from "react";
import PropTypes from "prop-types";

const MainButton = ({ children, onClick = () => null, className = "" }) => {
  return (
    <p onClick={onClick} role="button" className={`${className} mx-12 lg:mx-0 flex justify-center hover:bg-orange-600 transition-all font-bold uppercase text-white p-2 rounded-md cursor-pointer`}>
      {children}
    </p>
  );
};

MainButton.propTypes = {
  children: PropTypes.element.isRequired,
  onClick: PropTypes.func,
  className: PropTypes.string,
};

MainButton.defaultValues = {
  onClick: () => null,
  className: "",
};

export default MainButton;
